import { createSlice } from "@reduxjs/toolkit";


export const sidebarSlice = createSlice({
  name: "sidebarShow",
  initialState: true,
  reducers: {
    toggle: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { toggle } = sidebarSlice.actions;

export default sidebarSlice.reducer;
