import { configureStore } from '@reduxjs/toolkit'
import vehiclesReducer from './vehicles'
import sidebarReducer from './sidebar'
import vehicleTypesReducer from './vehicleTypes'
import vehicleGroupsReducer from './vehicleGroups'
import driversReducer from "./drivers"
import driverLogsReducer from "./driverLogs"

const store = configureStore({
    reducer:{
        sidebarShow:sidebarReducer,
        vehicles:vehiclesReducer,
        vehicleTypes:vehicleTypesReducer,
        vehicleGroups:vehicleGroupsReducer,
        drivers:driversReducer,
        driverLogs:driverLogsReducer,
    }
})

export default store
