import { createSlice } from "@reduxjs/toolkit";
import VEHICLES from '../assets/data/VEHICLES_DATA.json'

const initialStateValue = { value: VEHICLES};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: initialStateValue,
  reducers: {
    addVehicle: (state, action) => {
      state.value = [...state.value, {...action.payload,id:state.value.length+1}];
    },
    editVehicle: (state, action) => {
        const index = state.value.findIndex(vehicle => vehicle.id===action.payload.id)
        if(index>=0){
            const newState = [...state.value]
            newState[index]=action.payload
            state.value = newState
        }
    },
    deleteVehicle:(state, action) => {
        state.value=action.payload
    }
  },
});

export const { addVehicle, editVehicle, deleteVehicle } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
