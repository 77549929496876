import { createSlice } from "@reduxjs/toolkit";
import DRIVER_LOGS from '../assets/data/DRIVER_LOGS_DATA.json'

const initialStateValue = { value: DRIVER_LOGS};

export const driverLogsSlice = createSlice({
  name: "driverLogs",
  initialState: initialStateValue,
  reducers: {
    addDriverLog: (state, action) => {
      state.value = [...state.value, {...action.payload,id:state.value.length+1}];
    }
  },
});

export const { addDriverLog } = driverLogsSlice.actions;

export default driverLogsSlice.reducer;
