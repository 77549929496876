import { createSlice } from "@reduxjs/toolkit";
import DRIVERS from '../assets/data/DRIVERS_DATA.json'

const initialStateValue = { value: DRIVERS};

export const driversSlice = createSlice({
  name: "drivers",
  initialState: initialStateValue,
  reducers: {
    addDriver: (state, action) => {
      state.value = [...state.value, {...action.payload,id:state.value.length+1}];
    },
    editDriver: (state, action) => {
        const index = state.value.findIndex(driver => driver.id===action.payload.id)
        if(index>=0){
            const newState = [...state.value]
            newState[index]=action.payload
            state.value = newState
        }
    },
    deleteDriver:(state, action) => {
        state.value=action.payload
    }
  },
});

export const { addDriver, editDriver, deleteDriver } = driversSlice.actions;

export default driversSlice.reducer;
