import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {Spinner} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
const loading = (
  <div className="d-flex" style={{height:'50vh'}}>
    <Spinner className="m-auto" style={{width:'5rem',height:'5rem',borderWidth:'.5rem'}} animation="border" variant="primary" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'))



const theme = createTheme({
  typography: {
    tab: {
      fontSize: 20,
    },
  },
  palette: {
    common: {
      purple: 'purple',
    },
  },
})

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
      </ThemeProvider>
    )
  }
}

export default App