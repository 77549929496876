import { createSlice } from "@reduxjs/toolkit";
import VEHICLE_GROUPS from '../assets/data/VEHICLE_GROUPS_DATA.json'

const initialStateValue = { value: VEHICLE_GROUPS};

export const vehicleGroupsSlice = createSlice({
  name: "vehicleGroups",
  initialState: initialStateValue,
  reducers: {
    addVehicleGroup: (state, action) => {
      state.value = [...state.value, {...action.payload,id:state.value.length+1}];
    },
    editVehicleGroup: (state, action) => {
        const index = state.value.findIndex(vehicle => vehicle.id===action.payload.id)
        if(index>=0){
            const newState = [...state.value]
            newState[index]=action.payload
            state.value = newState
        }
    },
    deleteVehicleGroup:(state, action) => {
        state.value=action.payload
    }
  },
});

export const { addVehicleGroup, editVehicleGroup, deleteVehicleGroup } = vehicleGroupsSlice.actions;

export default vehicleGroupsSlice.reducer;
