import { createSlice } from "@reduxjs/toolkit";
import VEHICLE_TYPES from '../assets/data/VEHICLE_TYPES_DATA.json'

const initialStateValue = { value: VEHICLE_TYPES};

export const vehicleTypesSlice = createSlice({
  name: "vehicleTypes",
  initialState: initialStateValue,
  reducers: {
    addVehicleType: (state, action) => {
      state.value = [...state.value, {...action.payload,id:state.value.length+1}];
    },
    editVehicleType: (state, action) => {
        const index = state.value.findIndex(vehicle => vehicle.id===action.payload.id)
        if(index>=0){
            const newState = [...state.value]
            newState[index]=action.payload
            state.value = newState
        }
    },
    deleteVehicleType:(state, action) => {
        state.value=action.payload
    }
  },
});

export const { addVehicleType, editVehicleType, deleteVehicleType } = vehicleTypesSlice.actions;

export default vehicleTypesSlice.reducer;
